// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Welcome-text{margin-top:4em;margin-bottom:5em}", ""]);
// Exports
exports.locals = {
	"text": "onfido-sdk-ui-Welcome-text"
};
module.exports = exports;
