// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Capture-documentContainer{position:relative;overflow:hidden;margin:0 2.5em 1em}.onfido-sdk-ui-Capture-faceContainer{position:static}.onfido-sdk-ui-Capture-uploadFallback{cursor:pointer;text-decoration:underline}.onfido-sdk-ui-Capture-uploadFallback:hover{background-color:0 0 0 2px #5c6cff}.onfido-sdk-ui-Capture-uploadFallback:active{background-color:0 0 0 2px #232aad}", ""]);
// Exports
exports.locals = {
	"documentContainer": "onfido-sdk-ui-Capture-documentContainer",
	"faceContainer": "onfido-sdk-ui-Capture-faceContainer",
	"uploadFallback": "onfido-sdk-ui-Capture-uploadFallback"
};
module.exports = exports;
