// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Complete-wrapper{margin-top:7.25em}.onfido-sdk-ui-Complete-icon{height:4em;background-size:contain;background-image:url(\"data:image/svg+xml,%3Csvg width%3D%2256%22 height%3D%2256%22 viewBox%3D%220 0 56 56%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E  %3Ctitle%3E    complete-tick  %3C%2Ftitle%3E  %3Cg fill%3D%22none%22 fill-rule%3D%22evenodd%22%3E    %3Ccircle fill%3D%22%23353FF4%22 cx%3D%2228%22 cy%3D%2228%22 r%3D%2228%22%2F%3E    %3Cpath d%3D%22M37.723 16.373l-13.69 14.53-3.423-3.633c-1.512-1.6-3.964-1.6-5.476 0-1.512 1.608-1.512 4.214 0 5.813l6.16 6.544c1.513 1.61 3.964 1.61 5.477 0l16.43-17.44c1.51-1.61 1.51-4.215 0-5.814-1.513-1.61-3.964-1.61-5.477 0z%22 fill%3D%22%23FFF%22%2F%3E  %3C%2Fg%3E%3C%2Fsvg%3E\");margin-bottom:1.5em}", ""]);
// Exports
exports.locals = {
	"wrapper": "onfido-sdk-ui-Complete-wrapper",
	"icon": "onfido-sdk-ui-Complete-icon"
};
module.exports = exports;
