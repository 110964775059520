export default {
  INVALID_CAPTURE: {
    message: 'errors.invalid_capture.message',
    instruction: 'errors.invalid_capture.instruction',
  },
  INVALID_TYPE: {
    message: 'errors.invalid_type.message',
    instruction: 'errors.invalid_type.instruction',
  },
  UNSUPPORTED_FILE: {
    message: 'errors.unsupported_file.message',
    instruction: 'errors.unsupported_file.instruction',
  },
  INVALID_SIZE: {
    message: 'errors.invalid_size.message',
    instruction: 'errors.invalid_size.instruction',
  },
  NO_FACE_ERROR: {
    message: 'errors.no_face.message',
    instruction: 'errors.no_face.instruction',
  },
  MULTIPLE_FACES_ERROR: {
    message: 'errors.multiple_faces.message',
    instruction: 'errors.multiple_faces.instruction',
  },
  SERVER_ERROR: {
    message: 'errors.server_error.message',
    instruction: 'errors.server_error.instruction',
  },
  GLARE_DETECTED: {
    message: 'errors.glare_detected.message',
    instruction: 'errors.glare_detected.instruction',
  },
  SMS_FAILED: {
    message: 'errors.sms_failed.message',
    instruction: 'errors.sms_failed.instruction',
  },
  SMS_OVERUSE: {
    message: 'errors.sms_overuse.message',
    instruction: 'errors.sms_overuse.instruction',
  },
  CAMERA_NOT_WORKING: {
    message: 'errors.camera_not_working.message',
    instruction: 'errors.camera_not_working.instruction',
  },
  CAMERA_NOT_WORKING_NO_FALLBACK: {
    message: 'errors.camera_not_working.message',
    instruction: 'errors.camera_not_working_no_fallback.instruction',
  },
  CAMERA_INACTIVE: {
    message: 'errors.camera_inactive.message',
    instruction: 'errors.camera_inactive.instruction',
  },
  CAMERA_INACTIVE_NO_FALLBACK: {
    message: 'errors.camera_inactive.message',
    instruction: 'errors.camera_inactive_no_fallback.instruction',
  },
  LIVENESS_TIMEOUT: {
    message: 'errors.liveness_timeout.message',
    instruction: 'errors.liveness_timeout.instruction',
  },
  GENERIC_CLIENT_ERROR: {
    message: 'errors.generic_client_error.message',
    instruction: 'errors.generic_client_error.instruction',
  },
  FORBIDDEN_CLIENT_ERROR: {
    message: 'errors.forbidden_client_error.message',
    instruction: 'errors.forbidden_client_error.instruction',
  },
  INTERRUPTED_FLOW_ERROR: {
    message: 'errors.interrupted_flow_error.message',
    instruction: 'errors.interrupted_flow_error.instruction',
    icon: 'flowInterruptedIcon',
  },
  UNSUPPORTED_ANDROID_BROWSER: {
    message: 'errors.unsupported_android_browser.message',
    instruction: 'errors.unsupported_android_browser.instruction',
    icon: 'unsupportedBrowserIcon',
  },
  UNSUPPORTED_IOS_BROWSER: {
    message: 'errors.unsupported_ios_browser.message',
    instruction: 'errors.unsupported_ios_browser.instruction',
    icon: 'unsupportedBrowserIcon',
  },
}
