// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Select-wrapper{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-flex-basis:100%;flex-basis:100%}.onfido-sdk-ui-Select-select{max-width:100%}", ""]);
// Exports
exports.locals = {
	"wrapper": "onfido-sdk-ui-Select-wrapper",
	"select": "onfido-sdk-ui-Select-select"
};
module.exports = exports;
