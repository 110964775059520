// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-CameraError-errorContainer{left:0;position:absolute;right:0;top:0;z-index:4}.onfido-sdk-ui-CameraError-errorContainerType::before{content:\"\";display:block;height:100%;left:0;position:absolute;right:0;top:0;width:100%;z-index:1}.onfido-sdk-ui-CameraError-errorHasBackdrop{bottom:0}.onfido-sdk-ui-CameraError-errorHasBackdrop::before{content:\"\";display:block;height:100%;left:0;position:absolute;right:0;top:0;width:100%;z-index:1}.onfido-sdk-ui-CameraError-errorHasBackdrop::before{background-color:#1c1f21;opacity:.57}.onfido-sdk-ui-CameraError-errorMessage{top:3em;left:0;right:0;position:absolute;z-index:2}", ""]);
// Exports
exports.locals = {
	"errorContainer": "onfido-sdk-ui-CameraError-errorContainer",
	"errorContainerType": "onfido-sdk-ui-CameraError-errorContainerType",
	"errorHasBackdrop": "onfido-sdk-ui-CameraError-errorHasBackdrop",
	"errorMessage": "onfido-sdk-ui-CameraError-errorMessage"
};
module.exports = exports;
