// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-PageTitle-title{color:#2b2d33;font-weight:600;margin-bottom:1em}.onfido-sdk-ui-PageTitle-titleSpan{font-size:1.875em;line-height:1.34}@media(max-width: 479px){.onfido-sdk-ui-PageTitle-titleSpan{font-size:1.5em}}.onfido-sdk-ui-PageTitle-titleSpan:focus{outline:none}.onfido-sdk-ui-PageTitle-subTitle{color:#1e1e24;font-weight:600}.onfido-sdk-ui-PageTitle-titleWrapper{margin:2em 2.5em 1em;-webkit-flex-shrink:0;flex-shrink:0}@media(max-width: 479px){.onfido-sdk-ui-PageTitle-titleWrapper{margin:0 1.5em 1em}}.onfido-sdk-ui-PageTitle-smaller{margin-top:0}.onfido-sdk-ui-PageTitle-fullScreen{color:#fff;margin:0;z-index:1}.onfido-sdk-ui-PageTitle-fullScreen .onfido-sdk-ui-PageTitle-title,.onfido-sdk-ui-PageTitle-fullScreen .onfido-sdk-ui-PageTitle-subTitle{color:#fff}.onfido-sdk-ui-PageTitle-fullScreen .onfido-sdk-ui-PageTitle-title{margin-bottom:.5em}.onfido-sdk-ui-PageTitle-fullScreen .onfido-sdk-ui-PageTitle-titleSpan{font-size:1em;line-height:1.5}", ""]);
// Exports
exports.locals = {
	"title": "onfido-sdk-ui-PageTitle-title",
	"titleSpan": "onfido-sdk-ui-PageTitle-titleSpan",
	"subTitle": "onfido-sdk-ui-PageTitle-subTitle",
	"titleWrapper": "onfido-sdk-ui-PageTitle-titleWrapper",
	"smaller": "onfido-sdk-ui-PageTitle-smaller",
	"fullScreen": "onfido-sdk-ui-PageTitle-fullScreen"
};
module.exports = exports;
