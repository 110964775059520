// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Confirm-previewsContainer{width:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-flex:1;-webkit-flex:1;flex:1;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin-bottom:1em}.onfido-sdk-ui-Confirm-previewsContainer.onfido-sdk-ui-Confirm-previewsContainerIsFullScreen{top:0}.onfido-sdk-ui-Confirm-actionsContainer{margin:0 3em}@media(max-width: 479px){.onfido-sdk-ui-Confirm-actionsContainer{margin:0 1.5em}}.onfido-sdk-ui-Confirm-title{-webkit-box-flex:0;-webkit-flex:0 0 auto;flex:0 0 auto}.onfido-sdk-ui-Confirm-message{margin:2em 2.5em 1em}@media(max-width: 479px){.onfido-sdk-ui-Confirm-message{margin:0 1.5em 1em}}.onfido-sdk-ui-Confirm-actions{padding:0;width:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;margin-right:-1em}@media(max-width: 479px){.onfido-sdk-ui-Confirm-actions{margin-right:-0.5em}}.onfido-sdk-ui-Confirm-btn-secondary{margin-right:1em}@media(max-width: 479px){.onfido-sdk-ui-Confirm-btn-secondary{margin-right:.5em}}.onfido-sdk-ui-Confirm-error{display:block;margin-bottom:.5em}", ""]);
// Exports
exports.locals = {
	"previewsContainer": "onfido-sdk-ui-Confirm-previewsContainer",
	"previewsContainerIsFullScreen": "onfido-sdk-ui-Confirm-previewsContainerIsFullScreen",
	"actionsContainer": "onfido-sdk-ui-Confirm-actionsContainer",
	"title": "onfido-sdk-ui-Confirm-title",
	"message": "onfido-sdk-ui-Confirm-message",
	"actions": "onfido-sdk-ui-Confirm-actions",
	"btn-secondary": "onfido-sdk-ui-Confirm-btn-secondary",
	"error": "onfido-sdk-ui-Confirm-error"
};
module.exports = exports;
