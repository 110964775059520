// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-ProofOfAddress-Guidance-content{position:relative;margin:0 2.5em 1em;-webkit-box-flex:1;-webkit-flex:1;flex:1;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;display:-webkit-box;display:-webkit-flex;display:flex}@media(max-width: 479px){.onfido-sdk-ui-ProofOfAddress-Guidance-content{margin:0 1.5em 1em}}.onfido-sdk-ui-ProofOfAddress-Guidance-subTitle{font-size:.875em}.onfido-sdk-ui-ProofOfAddress-Guidance-subTitle::before{content:\"\";display:inline-block;width:1.1428571429em;height:1.1428571429em;background-image:url(\"data:image/svg+xml,%3Csvg width%3D%2216%22 height%3D%2216%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E  %3Cg fill%3D%22none%22 fill-rule%3D%22evenodd%22%3E    %3Ccircle fill%3D%22%232C3E4F%22 cx%3D%228%22 cy%3D%228%22 r%3D%228%22%2F%3E    %3Cg transform%3D%22translate(7 3)%22 fill%3D%22%23fff%22%3E      %3Crect y%3D%223%22 width%3D%222%22 height%3D%227%22 rx%3D%221%22%2F%3E      %3Ccircle cx%3D%221%22 cy%3D%221%22 r%3D%221%22%2F%3E    %3C%2Fg%3E  %3C%2Fg%3E%3C%2Fsvg%3E\");background-size:contain;vertical-align:middle;line-height:1;margin-right:.3571428571em;margin-top:-0.1428571429em}.onfido-sdk-ui-ProofOfAddress-Guidance-bolder{font-weight:600}.onfido-sdk-ui-ProofOfAddress-Guidance-makeSure{color:#1e1e24;font-weight:600;margin-bottom:.5em}@media(max-width: 479px){.onfido-sdk-ui-ProofOfAddress-Guidance-makeSure{text-align:left}}.onfido-sdk-ui-ProofOfAddress-Guidance-docImageContainer{position:relative;-webkit-box-flex:1;-webkit-flex:1;flex:1;width:21.25em;margin:0 auto}@media(max-width: 479px){.onfido-sdk-ui-ProofOfAddress-Guidance-docImageContainer{width:100%}}.onfido-sdk-ui-ProofOfAddress-Guidance-docImage{height:100%;width:100%;position:absolute;top:0;left:0;right:0;bottom:0}.onfido-sdk-ui-ProofOfAddress-Guidance-label{fill:#1e1e24;font-size:.875em}", ""]);
// Exports
exports.locals = {
	"content": "onfido-sdk-ui-ProofOfAddress-Guidance-content",
	"subTitle": "onfido-sdk-ui-ProofOfAddress-Guidance-subTitle",
	"bolder": "onfido-sdk-ui-ProofOfAddress-Guidance-bolder",
	"makeSure": "onfido-sdk-ui-ProofOfAddress-Guidance-makeSure",
	"docImageContainer": "onfido-sdk-ui-ProofOfAddress-Guidance-docImageContainer",
	"docImage": "onfido-sdk-ui-ProofOfAddress-Guidance-docImage",
	"label": "onfido-sdk-ui-ProofOfAddress-Guidance-label"
};
module.exports = exports;
