// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Spinner-loader{margin:auto;position:absolute;top:0;left:0;bottom:0;right:0}.onfido-sdk-ui-Spinner-loader{width:3em;height:3em;outline:none}@-webkit-keyframes onfido-sdk-ui-Spinner-ball-scale-ripple-multiple{0%{-webkit-transform:scale(0.1);transform:scale(0.1);opacity:1}70%{-webkit-transform:scale(1);transform:scale(1);opacity:.7}100%{opacity:0}}@keyframes onfido-sdk-ui-Spinner-ball-scale-ripple-multiple{0%{-webkit-transform:scale(0.1);transform:scale(0.1);opacity:1}70%{-webkit-transform:scale(1);transform:scale(1);opacity:.7}100%{opacity:0}}.onfido-sdk-ui-Spinner-inner{position:relative;-webkit-transform:translateX(1.5625em);transform:translateX(1.5625em)}.onfido-sdk-ui-Spinner-inner>div:nth-child(0){-webkit-animation-delay:-0.8s;animation-delay:-0.8s}.onfido-sdk-ui-Spinner-inner>div:nth-child(1){-webkit-animation-delay:-0.6s;animation-delay:-0.6s}.onfido-sdk-ui-Spinner-inner>div:nth-child(2){-webkit-animation-delay:-0.4s;animation-delay:-0.4s}.onfido-sdk-ui-Spinner-inner>div:nth-child(3){-webkit-animation-delay:-0.2s;animation-delay:-0.2s}.onfido-sdk-ui-Spinner-inner>div{-webkit-animation-fill-mode:both;animation-fill-mode:both;position:absolute;top:-0.125em;left:-1.625em;width:3.125em;height:3.125em;border-radius:100%;border:.125em solid #ccc;-webkit-animation:onfido-sdk-ui-Spinner-ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);animation:onfido-sdk-ui-Spinner-ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8)}", ""]);
// Exports
exports.locals = {
	"loader": "onfido-sdk-ui-Spinner-loader",
	"inner": "onfido-sdk-ui-Spinner-inner",
	"ball-scale-ripple-multiple": "onfido-sdk-ui-Spinner-ball-scale-ripple-multiple"
};
module.exports = exports;
