// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".onfido-sdk-ui-Camera-camera{width:100%;height:auto;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}.onfido-sdk-ui-Camera-camera.onfido-sdk-ui-Camera-docAutoCaptureFrame{display:block}.onfido-sdk-ui-Camera-container{overflow:hidden}.onfido-sdk-ui-Camera-container::before{content:\"\";display:block;padding-top:70%}.onfido-sdk-ui-Camera-overlay{width:100%;height:100%;top:0;position:absolute;z-index:0}.onfido-sdk-ui-Camera-video{display:block;height:100%;z-index:0}@supports not (-ms-ime-align: auto){.onfido-sdk-ui-Camera-video{width:100%;top:0;left:0;margin:auto;object-fit:cover}}.onfido-sdk-ui-Camera-actions{position:absolute;z-index:1000;bottom:3em;left:1em;right:1em}.onfido-sdk-ui-Camera-actions.onfido-sdk-ui-Camera-disabled{z-index:0}.onfido-sdk-ui-Camera-btn{cursor:pointer;outline-offset:.375em;background-color:#fff;font-size:inherit;border-radius:50%;border:.1875em solid #000;-webkit-box-shadow:0 0 0 .25em #fff;box-shadow:0 0 0 .25em #fff;height:3.5em;margin-left:auto;margin-right:auto;padding:0;width:3.5em}.onfido-sdk-ui-Camera-btn:hover{background-color:#5c6cff}.onfido-sdk-ui-Camera-btn:active{background-color:#232aad}.onfido-sdk-ui-Camera-btn:disabled{cursor:not-allowed;background-color:#2b2d32}.onfido-sdk-ui-Camera-webcamContainer{position:absolute;top:0;height:100%}@supports(-ms-ime-align: auto){.onfido-sdk-ui-Camera-webcamContainer{left:50%;margin:auto;-webkit-transform:translate(-50%, 0%);transform:translate(-50%, 0%)}}", ""]);
// Exports
exports.locals = {
	"camera": "onfido-sdk-ui-Camera-camera",
	"docAutoCaptureFrame": "onfido-sdk-ui-Camera-docAutoCaptureFrame",
	"container": "onfido-sdk-ui-Camera-container",
	"overlay": "onfido-sdk-ui-Camera-overlay",
	"video": "onfido-sdk-ui-Camera-video",
	"actions": "onfido-sdk-ui-Camera-actions",
	"disabled": "onfido-sdk-ui-Camera-disabled",
	"btn": "onfido-sdk-ui-Camera-btn",
	"webcamContainer": "onfido-sdk-ui-Camera-webcamContainer"
};
module.exports = exports;
